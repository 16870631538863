import React from 'react';
import Cookies from 'universal-cookie';
import HTMLParser from 'html-react-parser';
import { Link } from 'gatsby';

import { constructLink, formColor } from '../../helper';
import fontSizeSwitch from '../../helpers/fontSizeSwitch';
import styles from './styles.module.css';

const cookies = new Cookies();

const CookieWarning = ({ themeData, cookie, pagePathList, articlePathList, categoryPathList, buttons, images }) => {
  let linkButton;
  let declineButton;
  let acceptButton;
  if (
    cookie?.cookieLink?.active &&
    cookie?.cookieLink?.format === 'BUTTON' &&
    cookie?.cookieLink?.formatData?.styles?.id
  ) {
    linkButton = buttons?.find((b) => b.id === cookie.cookieLink.formatData.styles.id);
  }

  if (cookie?.cookieDecline?.id) declineButton = buttons?.find((b) => b.id === cookie.cookieDecline.id);
  if (cookie?.cookieButton?.id) acceptButton = buttons?.find((b) => b.id === cookie.cookieButton.id);

  const handleClick = () => {
    const d = new Date();
    d.setFullYear(d.getFullYear() + 1);
    cookies.set('cookiesAccepted', true, { path: '/', expires: d });
    const wrapper = document.getElementById('CookieWarningWrapper');
    if (wrapper) wrapper.style.display = 'none';
  };

  let titleColor;
  let textColor;
  let bgColor;
  let btnBgColor;
  let btnBorderColor;
  let btnColor;
  let linkColor;

  if (cookie.colour === 'light') {
    titleColor = '#000';
    textColor = '#000';
    [, bgColor] = themeData?.colors;
    [btnBgColor] = themeData?.colors;
    [btnBorderColor] = themeData?.colors;
    [, btnColor] = themeData?.colors;
    [, , , linkColor] = themeData?.colors;
  } else if (cookie.colour === 'dark') {
    titleColor = '#FFF';
    textColor = '#FFF';
    [, , , bgColor] = themeData?.colors;
    [, , , btnBgColor] = themeData?.colors;
    [btnBorderColor] = themeData?.colors;
    btnColor = '#fff';
    [, linkColor] = themeData?.colors;
  } else {
    bgColor = formColor({ solid: cookie?.bgColor }, false, cookie?.bgOpacity ?? 1, undefined, themeData?.colors)
      .backgroundColor;
    textColor = formColor({ solid: cookie?.textColor }, false, cookie?.textOpacity ?? 1, undefined, themeData?.colors)?.backgroundColor;
    titleColor = textColor;
    linkColor = formColor({ solid: cookie?.linkColor }, false, cookie?.linkOpacity ?? 1, undefined, themeData?.colors)?.backgroundColor;
  }

  let link;
  if (cookie?.cookieLink?.active) {
    ({ link } = constructLink(cookie.cookieLink, pagePathList, articlePathList, categoryPathList));
  }

  const getBtnStyle = (buttonStyle) => {
    let borderRadius;
    let boxShadow;

    let bColorHex = themeData?.colors[2];
    let bThickness = 0;

    if (buttonStyle?.border?.active) {
      if (buttonStyle.border.color !== '') bColorHex = buttonStyle.border.color;
      bThickness = `${buttonStyle.border.thickness}px`;
      borderRadius = `${buttonStyle.border.radius}px`;
    }

    const bColor = formColor(
      { solid: bColorHex },
      false,
      buttonStyle.border?.opacity >= 0 ? buttonStyle.border.opacity : 1,
      undefined,
      themeData?.colors,
    );

    const border = `${bColor.backgroundColor} solid ${bThickness}`;

    if (buttonStyle.shadow?.active) {
      const { x, y, blur, spread } = buttonStyle.shadow;

      let shadow = buttonStyle.shadow.color;
      if (buttonStyle.shadow.color === '') [, , shadow] = themeData?.colors;

      const sColor = formColor({ solid: shadow }, false, buttonStyle.shadow.opacity, undefined, themeData?.colors);

      boxShadow = `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`;
    }

    const font = buttonStyle.fontColor?.color ?? '#FFF';
    const fontColor = formColor(
      { solid: font },
      undefined,
      buttonStyle.fontColor?.opacity ?? 1,
      undefined,
      themeData?.colors,
    );

    const backgroundColor = { solid: buttonStyle.backgroundColor?.solid || themeData?.colors[0] };
    const buttonBgColor = formColor(
      backgroundColor,
      false,
      buttonStyle.backgroundColor?.opacity ?? 1,
      undefined,
      themeData?.colors,
    );

    let fontFamily;
    let fontSize;
    let textTransform;
    let fontWeight;
    if (themeData) {
      fontFamily = themeData?.typography?.button?.name;
      fontSize = fontSizeSwitch(themeData?.typography?.button?.fontSize);
      textTransform = themeData?.typography?.button?.textTransform;
      fontWeight = themeData?.typography?.button?.weight;
    }

    return {
      borderRadius,
      border,
      boxShadow,
      fontFamily,
      fontSize,
      fontWeight,
      textTransform,
      color: fontColor?.backgroundColor,
      backgroundColor: buttonBgColor?.backgroundColor,
    };
  };

  const declineButtonStyle = declineButton && getBtnStyle(declineButton);
  const acceptButtonStyle = acceptButton && getBtnStyle(acceptButton);

  let finishedLink;
  const { cookieLink } = cookie;
  if (cookieLink?.active && cookieLink?.format && cookieLink?.type) {
    let linkContent;
    let linkButtonStyle;
    switch (cookieLink.format) {
      case 'BUTTON':
        linkButtonStyle = linkButton && getBtnStyle(linkButton);
        linkContent = (
          <button className={styles.btn} style={linkButtonStyle}>
            {HTMLParser(cookieLink.name)}
          </button>
        );
        break;
      case 'ICON': {
        let color = cookieLink.formatData?.color || '#000000';
        color = formColor({ solid: color }, false, 1, undefined, themeData?.colors).backgroundColor;
        linkContent = (
          <span
            className={`entypo ${cookieLink.formatData?.class}`}
            aria-hidden
            style={{
              fontSize: cookieLink.formatData?.size,
              color,
            }}
          />
        );
        break;
      }
      case 'IMAGE':
        linkContent = <Image id={cookieLink.formatData.id} alt="Img" sizes="15vw" className="image" images={images} />;
        break;
      case 'TEXT':
        linkContent = (
          <span style={{ color: linkColor, textDecoration: 'underline' }}>
            {HTMLParser(cookieLink.name)}
          </span>
        );
        break;
      default:
        break;
    }

    if (link && ['PAGE', 'SECTION', 'ARTICLE'].includes(cookieLink?.type)) {
      finishedLink = (
        <Link to={link} draggable="false" className={styles.link}>
          {linkContent}
        </Link>
      );
    } else if (link && cookieLink?.type) {
      finishedLink = (
        <a draggable="false" className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
          {linkContent}
        </a>
      );
    }
  }

  return (
    <div
      id="CookieWarningWrapper"
      className={styles[`warningBackground${cookie.colour}`]}
    >
      <div style={{ backgroundColor: bgColor }} className={styles[cookie.layout]}>
        <div className={`container ${styles.container}`}>
          <div className="row">
            {cookie.cookieTitle.active && (
              <div className={`col-12 ${styles.titleWrapper}`}>
                <h4 style={{ color: titleColor }}>
                  {HTMLParser(cookie.cookieTitle.text)}
                </h4>
              </div>
            )}
            <div className={`col-12 ${styles.elementWrapper}`}>
              <span style={{ color: textColor }}>{HTMLParser(cookie?.cookieText?.text ?? cookie.cookieText)}</span>
            </div>
            <div className={`col-12 ${styles.btnWrapper}`}>
              {finishedLink}
              {cookie?.cookieDecline?.active && (
                <button
                  className={styles.decline}
                  style={
                    declineButtonStyle || {
                      color: btnColor,
                      backgroundColor: btnBgColor,
                      border: `2px solid ${btnBorderColor}`,
                    }
                  }
                  onClick={handleClick}
                >
                  {cookie?.cookieDecline.text}
                </button>
              )}
              {(typeof cookie?.cookieButton === 'string' || cookie?.cookieButton?.active) && (
                <button
                  className={styles.btn}
                  style={
                    acceptButtonStyle || {
                      color: btnColor,
                      backgroundColor: btnBgColor,
                      border: `2px solid ${btnBorderColor}`,
                    }
                  }
                  onClick={handleClick}
                >
                  {cookie?.cookieButton?.text ?? cookie.cookieButton}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default CookieWarning;
