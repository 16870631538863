import React from 'react';
import { Link } from 'gatsby';

import { formColor } from '../../../../helper';
import ButtonEditor from '../../../EditorPanel/ButtonEditor';
import Image from '../../../Image';
import CustomSectionMedia from '../../../EditorPanel/ManageSections/CustomSectionMedia';

import SubLinks from './SubLinks';
import styles from './styles.module.css';

class NavLink extends React.PureComponent {
  hoverMenuLink = (event, target) => {
    if (event.type === 'mouseenter') {
      if (event.target.closest('.NavLink') && target !== this.props.showSubPageLink) {
        this.props.onMenuLinkClick(target);
      }
    } else if (event.type === 'mouseleave') {
      if (!event.relatedTarget.closest('.NavLink') && !event.target.className.includes('arrow')) {
        this.props.onMenuLinkClick(target);
      }
    }
  };

  render() {
    const positionRelative = {
      position: 'relative',
    };
    const { header } = this.props;
    const rawLink = this.props.link;
    let link;
    let targetValue = rawLink.openLinkInNewTab ? '_blank' : '_self';

    let button;
    if (rawLink.format === 'BUTTON') {
      button = (
        <ButtonEditor
          themeData={this.props.themeData}
          button="Button"
          data={rawLink.formatData}
          text={rawLink.text || rawLink.name || rawLink.path || ''}
          buttons={this.props.buttons}
          invert={header && header.type === 'invert'}
        />
      );
    }

    let lineHeight = 1.5;
    if (rawLink.format === 'IMAGE') lineHeight = 0;
    else if (rawLink.format === 'BUTTON') lineHeight = 1.25;

    let style = {
      ...positionRelative,
      color: this.props.color,
      lineHeight,
    };
    if (this.props.isBurger) {
      style = { ...style, fontSize: '1.25rem' };
    }

    let icon;
    if (rawLink.format === 'ICON' && rawLink.formatData && rawLink.type !== 'EXTERNAL') {
      let { color } = rawLink.formatData;
      if (color) {
        color = formColor(
          { solid: color },
          false,
          1,
          undefined,
          this.props.themeData.colors,
          header && header.type === 'invert',
        ).backgroundColor;
      }
      icon = (
        <i
          className={`entypo ${rawLink.formatData.class}`}
          style={{ fontSize: rawLink.formatData.size, color }}
          aria-hidden
        />
      );
    }

    let image;
    if (['IMAGE', 'ICON'].includes(rawLink.format) && rawLink.type === 'EXTERNAL') {
      image = (
        <CustomSectionMedia
          id={rawLink.formatData?.id}
          className={rawLink.format === 'IMAGE' ? 'navbarImage' : undefined}
          imageStyleName={rawLink.format === 'IMAGE' ? 'navbarImage' : undefined}
          colors={this.props.themeData.colors}
          data={
            rawLink.format === 'IMAGE'
              ? { id: rawLink.formatData?.id, linkObj: rawLink, width: rawLink.formatData?.width }
              : { icon: rawLink.formatData, linkObj: rawLink }
          }
          images={this.props.images}
        />
      );
    } else if (rawLink.format === 'IMAGE' && rawLink.formatData?.id) {
      image = (
        <Image
          id={rawLink.formatData.id}
          alt={`Navbar_Image_${rawLink.formatData.id}`}
          className="navbarImage"
          imageStyleName="navbarImage"
          images={this.props.images}
        />
      );
    }

    const linkContent = (
      <>
        {image}
        {button}
        {icon}
        {rawLink.format === 'TEXT' &&
          ((rawLink.type === 'EXTERNAL' && (rawLink.text || rawLink.path)) ||
            ((rawLink.type === 'ARTICLE' || rawLink.type === 'FILE') && (rawLink.text || rawLink.name)) ||
            rawLink.name)}
      </>
    );

    if (rawLink.type === 'FILE') targetValue = '_blank';

    if (rawLink.type === 'EXTERNAL' || rawLink.type === 'FILE' || targetValue === '_blank') {
      let url = rawLink.path;
      if (rawLink.type === 'SECTION') url = `${rawLink.path}#${rawLink.id}`;
      link = (
        <a
          href={url}
          className={`${
            styles[`menu${rawLink.children && rawLink.children.length > 0 && !this.props.isBurger ? 'Main' : ''}Link`]
          } ${styles.Link}`}
          style={style}
          target={targetValue}
          rel="noopener noreferrer"
        >
          {linkContent}
        </a>
      );
    } else {
      let url = rawLink.path || '/';
      if (rawLink.type === 'SECTION') url = `${url}#${rawLink.id}`;
      link = (
        <Link
          to={url}
          state={rawLink.type === 'SECTION' ? { scrollToSection: rawLink.id } : undefined}
          className={`${
            styles[`menu${rawLink.children && rawLink.children.length > 0 && !this.props.isBurger ? 'Main' : ''}Link`]
          } ${styles.Link}`}
          style={style}
          activeStyle={rawLink.type !== 'SECTION' ? this.props.activeStyle : undefined}
          onClick={this.props.onLinkClick}
          draggable="false"
        >
          {linkContent}
        </Link>
      );
    }

    const fontSize =
      this.props.themeData.typography.navigation.fontSize === 'Small'
        ? '0.85rem'
        : this.props.themeData.typography.navigation.fontSize === 'Normal'
        ? '1rem'
        : '1.25rem';

    const themeNavigationStyle = {
      fontFamily: this.props.themeData.typography.navigation.name,
      fontWeight: this.props.themeData.typography.navigation.weight,
      lineHeight: this.props.themeData.typography.navigation.lineHeight,
      letterSpacing: this.props.themeData.typography.navigation.letterSpacing,
      fontSize,
    };

    let divider;
    if (this.props.divider && this.props.divider > 1) {
      let div;
      switch (this.props.divider) {
        case 2:
          div = <span style={themeNavigationStyle}>&bull;</span>;
          break;
        case 3:
          div = <span style={themeNavigationStyle}>&#xFF5C;</span>;
          break;
        default:
        // Nothing here
      }

      divider = <div style={{ color: this.props.color, display: 'inline-flex', alignItems: 'center' }}>{div}</div>;
    }

    return (
      <>
        {this.props.additionalDivider && divider}
        <div
          className={`NavLink noEvents ${
            styles[
              `menuItem${rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 ? 'Sub' : ''}`
            ]
          }`}
          key={`NavigationBar_${rawLink.id}`}
          onMouseEnter={
            !this.props.isBurger && rawLink.children && rawLink.children.length > 0
              ? (e) => this.hoverMenuLink(e, rawLink.id)
              : undefined
          }
          onMouseLeave={
            !this.props.isBurger && rawLink.children && rawLink.children.length > 0
              ? (e) => this.hoverMenuLink(e, rawLink.id)
              : undefined
          }
          style={
            rawLink.format === 'ICON'
              ? { marginLeft: '5px', marginRight: '5px' }
              : rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 && this.props.isBurger
              ? { justifyContent: 'center', flexWrap: 'wrap' }
              : undefined
          }
        >
          {rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 && this.props.isBurger ? (
            <div style={{ width: '100%' }}>{link}</div>
          ) : (
            link
          )}
          {!this.props.isBurger && rawLink.format === 'TEXT' && rawLink.children && rawLink.children.length > 0 && (
            <div
              className={styles.Link}
              style={{ display: 'inline-flex', alignItems: 'center' }}
              role="button"
              tabIndex="0"
              onKeyPress={() => this.props.onMenuLinkClick(rawLink.id)}
              onClick={() => this.props.onMenuLinkClick(rawLink.id)}
            >
              <span
                style={{ ...themeNavigationStyle, ...{ color: this.props.color } }}
                className="entypo icon-down-open"
              />
            </div>
          )}
          {(this.props.isBurger || this.props.showSubPageLink === rawLink.id) &&
            rawLink.format === 'TEXT' &&
            rawLink.children &&
            rawLink.children.length > 0 && (
              <SubLinks
                onLinkClick={this.props.onLinkClick}
                themeData={this.props.themeData}
                color={this.props.color}
                activeStyle={this.props.activeStyle}
                links={rawLink.children}
                isBurger={this.props.isBurger}
                subColor={this.props.subColor}
                header={header}
                images={this.props.images}
              />
            )}
        </div>
        {divider}
      </>
    );
  }
}

export default NavLink;
